import React, { useEffect, useState } from 'react';
import TableWrapper from '../../../components/table-wrapper';
import { columns } from './columns';
import { useGetBillsListMutation } from '../api-slice';
import useLabels from '../../../hooks/use-labels';
import { useBillTypesQuery, useCreateBillsFactorMutation } from 'src/features/management/management-api-slice';
import ExcelUploadModal from '../../excel-upload/add';
import ManagementItemModal from '../item-modal';
import PaymentManagement from '../payment';
import { selectedProps } from '../../../components/table-wrapper/types';
import useLocalStorage from '../../../hooks/use-local-storage';

const AddComponents = () => <div>
  <ManagementItemModal />
  <ExcelUploadModal />
</div>;

const ManagementListView = () => {
  const { labels } = useLabels();
  const { data: billsTypes = [] } = useBillTypesQuery({});
  const [createBillsFactor] = useCreateBillsFactorMutation();
  const [selectedRows, setSelectedRows] = useLocalStorage<selectedProps>('payment',{
    type: 'off',
    value: undefined,
    query: undefined,
  });

  return <div>
    <TableWrapper
      pagination={'online'}
      api={useGetBillsListMutation}
      title={'مدیریت قبض'}
      columns={columns}
      AddComponent={AddComponents}
      uniqKey={'bill_id'}
      downloadApi={createBillsFactor}
      downloadKey={'bill'}
      selectable={{ ...selectedRows, setValue: setSelectedRows }}
      filterOptions={[
        {
          name: 'label_ids',
          placeholder: 'برچسب',
          type: 'multiselect',
          options: labels.map((e) => {
            return { name: e.label, value: e.value };
          }),
        },
        {
          name: 'bill_type_id',
          type: 'multiselect',
          placeholder: 'نوع قبض',
          options: billsTypes.map((e) => {
            return { name: e.name, value: e.id };
          }),
        },
        {
          name: 'search',
          placeholder: 'شناسه و عنوان قبض',
          type: 'string',
        },
      ]}
    />
    <div className={'relative'}>
      <PaymentManagement {...selectedRows} setValue={setSelectedRows} />
    </div>
  </div>;
};

export default ManagementListView;