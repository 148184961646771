import React from 'react';
import { tr } from '../constants';
import s from './table.module.css';
import { generateRandomKey } from '../../../utils/helpers';
import Empty from '../../empty';
import { TableWrapperProps } from '../types';
import Checkbox from '../../checkbox';
import { SortIcon } from '../../../assets/icons';

const Table = ({ props, renew, loading, setFilters }: {
  props: Pick<TableWrapperProps, 'selectable' | 'columns' | 'rows' | 'uniqKey'>,
  renew: any,
  loading: any,
  setFilters: any,
}) => {
  const { columns, uniqKey, selectable, rows } = props;


  const toggleRowSelection = (row: any) => {
    if (selectable?.value?.find((selectedRow: any) => selectedRow?.[uniqKey || 'id'] === row?.[uniqKey || 'id'])) {
      selectable?.setValue({
        ...selectable.value,
        type: selectable?.type,
        query: selectable?.query,
        value: selectable?.value?.filter((selectedRow: any) => selectedRow?.[uniqKey || 'id'] !== row?.[uniqKey || 'id']),
      });
    } else {
      selectable?.setValue({
        type: selectable?.type,
        value: [...selectable?.value, row],
        query: selectable?.query,
      });
    }
  };

  const checkFinder = (item: any) => {
    if (selectable?.type === 'all') {
      if (selectable?.value?.some(selectedRow => selectedRow[uniqKey || 'id'] === item))
        return false;
      return true;
    } else if (selectable?.value?.some(selectedRow => selectedRow[uniqKey || 'id'] === item))
      return true;
    return false;
  };

  return (
    <div className={s.wrapper}>
      <table className={s.table}>
        <thead className={'z-10 sticky top-0 bg-white'}>
        <tr>
          {(selectable?.setValue && selectable?.type !== 'off') &&
            <th className={`${s.tableHead} rounded-r-3xl`}></th>}
          <th
            className={`${s.tableHead} ${!(selectable?.setValue && selectable?.type !== 'off') ? 'rounded-r-3xl' : ''}`}>
            {tr.record}
          </th>
          {columns.map(({ title, field, hasSort }: any, index: number) => (
            <th
              key={generateRandomKey()}
              onClick={() => hasSort ? setFilters(field) : {}}
              className={`${s.tableHead} ${columns.length - 1 === index ? 'rounded-l-3xl' : ''}`}
            >
              <span className={'flex items-center justify-center gap-1'}>
                {title}
                {hasSort ? <SortIcon /> : ''}
              </span>
            </th>
          ))}
        </tr>
        </thead>
        <tbody className={s.body}>
        {rows?.length > 0 ? (
          rows?.map((row: any, index: number) => (
            <tr key={generateRandomKey()} className="hover:bg-gray-100">
              {selectable?.setValue && selectable?.type !== 'off' &&
                <td className={`${s.td} w-[20px] ${selectable?.setValue ? ' h-[55px]' : ''}`}>
                  <Checkbox checked={checkFinder(row[uniqKey || 'id'])} onChange={() => toggleRowSelection(row)} />
                </td>}
              <td className={`${s.td} w-[20px] ${selectable ? ' h-[55px]' : ''}`}>
                {index + 1}
              </td>
              {columns.map(({ title, hasSort, field, renderCell }) => (
                <td key={generateRandomKey()}
                    className={`${s.td} ${selectable ? ' h-[55px]' : ''}`}>
                  {renderCell ?
                    typeof renderCell(row) === 'object' ? renderCell(row, renew) :
                      <p>
                        {renderCell(row)}
                      </p> :
                    field.split('.').length <= 1 ?
                      row?.[field] :
                      row?.[field.split('.')[0]]?.[field.split('.')[1]]}
                </td>
              ))}
            </tr>
          ))
        ) : loading ? '' : (
          <tr>
            <td colSpan={columns.length + 1} align={'center'} className={s.noRecord}>
              <Empty title="اطلاعاتی یافت نشد!" />
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;