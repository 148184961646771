import React, { useEffect, useState } from 'react';
import { useBillPaymentMutation } from '../management-api-slice';
import { priceFormatter } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import Modal from '../../../components/modal';
import { CashPaymentIcon, IpgPaymentIcon } from '../../../assets/icons';

const bg = [
  <CashPaymentIcon />,
  <IpgPaymentIcon />,
];

interface ComponentProps {
  type: 'single' | 'all' | 'off';
  query?: any;
  value: object[];
  setValue: any;
  setExtraQuery?: any;
}

const PaymentManagement = ({ type, value, setValue, query }: ComponentProps) => {
  const [billPaymentApi] = useBillPaymentMutation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();
  const [payment, setPayment] = useState<any>(undefined);
  const [body, setBody] = useState<any>({
    bill_ids: [],
    excluded_bills: [],
    label_ids: [],
    bill_type_ids: [],
    payment_method: undefined,
    select_all: false,
    payment_confirmation: false,
  });

  useEffect(() => {
    if (type !== 'off')
      setBody({
        ...body,
        bill_ids: type === 'single' ? value?.map(({ id }: any) => id) : [],
        excluded_bills: type === 'all' ? value.slice(1, value.length).map(({ id }: any) => id) : [],
        label_ids: query?.label_ids,
        bill_type_ids: query?.bill_type_id,
        payment_method: payment,
        select_all: type === 'all' && !query?.label_ids?.length && !query?.bill_type_id?.length,
      });
  }, [query, value, type, payment]);

  useEffect(() => {
    getData(false);
  }, [body]);

  const getData = async (confirm: boolean = false) => {
    console.log(body);
    console.log(query);
    if (type !== 'off' && query?.filter_payment_available) {
      const res = await billPaymentApi({ ...body,payment_confirmation:confirm })?.unwrap();
      if (!confirm) {
        setData(res?.data);
      } else {
        toast.success(res?.message);
        setOpen(false);
        setValue({
          type: 'off',
          value: undefined,
          query: undefined,
        });
      }
    }
  };

  useEffect(() => {
    if (open)
      getData(false);
  }, [open]);

  return <>
    {open ?
      <Modal open={open} onClose={() => setOpen(false)}>
        <div
          className={'absolute left-0 top-0 bg-white w-[calc(100%-220px)] h-full z-50 flex flex-col justify-center items-center'}>
          <h2 className={'mb-[50px]'}>لطفا روش پرداخت را انتخاب نمایید.</h2>
          {data?.available_payment_methods?.map(({ image,name, id }: any) =>
            <button
              className={`border border-gray-100 rounded-full h-[75px] mb-2 w-[400px] flex px-4 items-center ${id === payment ? 'border-primary border-2' : ''}`}
              onClick={() => setPayment(id)}
            >
              <img src={image} alt="image" />
              {/* {bg[id - 1]} */}
              <span className="mr-2 text-sm">{name}</span>
            </button>,
          )}
          <div className="flex justify-around items-center bg-primary text-white rounded-full h-[75px] w-[400px]">
            <p>مبلغ قابل پرداخت</p>
            <p>{priceFormatter(data?.total_amount)} ریال</p>
          </div>
          <div className={'mt-[35px] flex justify-center'}>
            <button className={'rounded-full h-[40px] px-8 mx-2 bg-primary text-white'}
              onClick={() => getData(true)}
            >پرداخت
            </button>
            <button className={'rounded-full h-[40px] px-8 mx-2 border border-gray-300'}
                    onClick={() => setOpen(!open)}>انصراف
            </button>
          </div>
        </div>
      </Modal>
      : ''}
    {type !== 'off' && (
      <div
        className="absolute left-0 bottom-[60px] w-[calc(100%-40px)] mx-[20px] p-[7px] flex justify-between rounded-3xl bg-primary">
        <div className={'flex justify-between items-center'}>
          {data?.total_amount ? <p className={'text-white mx-2'}>جمع قبوض
            : {priceFormatter(data?.total_amount)} ریال</p> : ''}
          <p className={'text-white mx-2 text-sm'}>
            تعداد: {data?.bills_count} قبض
          </p>
        </div>
        <div>
          <button
            className={'bg-white text-primary text-sm rounded-3xl mx-1 px-12 py-2'}
            onClick={() => setOpen(!open)}
          >پرداخت
          </button>
        </div>
      </div>
    )}
  </>;
};

export default PaymentManagement;