import { useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { VictorIcon } from '../../assets/icons';

const InputOption = (
  { getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles

  const style = {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className="flex justify-center items-center border-[1.5px] border-[#4A4A4A] rounded-[5px] w-[17px] h-[17px] py-[1px] px-[2px] mx-2 mb-[3px]">
        <input type="checkbox" className="here peer sr-only" checked={isSelected} onChange={() => {
        }} />
        <VictorIcon className="opacity-0 peer-checked:opacity-100" />
      </div>
      {children}
    </components.Option>
  );
};

const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#DCDCDC',
    minHeight: '40px',
    height: '40px',
    borderRadius: 30,
    alignContent: 'center',
    boxShadow: state.isFocused ? null : null,
    padding: '8px',
    cursor: 'pointer',

    '&:hover': {
      borderColor: state.isFocused ? '#DCDCDC' : '#DCDCDC',
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    alignContent: 'center',
    color: '#656565',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21.88px',
    padding: '6px',
    flexDirection: !state.isFocused ? 'column' : 'row',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: '0px solid #fff',
    color: '#656565',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21.88px',
    borderRadius: 8,
    outline: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '5px 8px',
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#fff' : '#fff',
    cursor: 'pointer',
    padding: '9px',
    paddingRight: '5px',
    borderRadius: state.isSelected ? 30 : 0,
    '&::nth-of-type(n)': {
      border: '0.25px dashed #A5B5E4',
    },
    '&:hover': {
      background: state.isSelected ? '#F5F8FA' : '#F5F8FA',
      borderRadius: 30,
      // borderRadius: 50,
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
};

const Dropdown = (
  { placeholder, options, handleForm, className, isMulti = false ,isSearchable = false},
) => {

  return <ReactSelect
    placeholder={placeholder}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        neutral50: '#656565',
      },
    })}
    isMulti={isMulti}
    options={options}
    styles={selectStyles}
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    onChange={handleForm}
    getOptionLabel={(item: any) => item.name}
    getOptionValue={(item: any) => item.name}
    isSearchable={isSearchable}
    noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
    loadingMessage={() => 'در حال دریافت'}
    className={className?className:`w-40 mx-1`}
    components={{
      Option: InputOption,
    }}
  />;
};

export default Dropdown;