import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';
import { joiResolver } from '@hookform/resolvers/joi';
import { CloseTwoIcon, EditTwoIcon, VictorIcon } from 'src/assets/icons';
import TextMessage from 'src/components/text-message';
import s from './item-modal.module.css';
import { useBillTypesQuery, useCreateBillMutation, useEditBillMutation } from '../management-api-slice';
import { billSchema } from '../schemas';
import useLabels from 'src/hooks/use-labels';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import Modal from '../../../components/modal';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { selectLabels } from 'src/features/labels/labels-slice';

export const InputOption = ({
                              getStyles,
                              Icon,
                              isDisabled,
                              isFocused,
                              isSelected,
                              children,
                              innerProps,
                              ...rest
                            }: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles

  const style = {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className="flex justify-center items-center border-[1.5px] border-[#4A4A4A] rounded-[5px] w-[17px] h-[17px] py-[1px] px-[2px] mx-2 mb-[3px]">
        <input type="checkbox" className="here peer sr-only" checked={isSelected} onChange={() => {
        }} />
        <VictorIcon className="opacity-0 peer-checked:opacity-100" />
      </div>
      {children}
    </components.Option>
  );
};

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#F5F7FD',
    minHeight: '40px',
    height: '40px',
    borderRadius: 5,
    alignContent: 'center',
    boxShadow: state.isFocused ? null : null,
    padding: '8px',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '14px',
    border: state.isFocused ? '1px solid #DCDCDC' : '1px solid #DCDCDC',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    alignContent: 'center',
    padding: '6px',
    flexDirection: !state.isFocused ? 'column' : 'row',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '2px 8px',
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F5F8FA' : '#fff',
    color: '#393939',
    cursor: 'pointer',
    borderRadius: state.isSelected ? 30 : 0,
    '&:hover': {
      background: state.isSelected ? '#F5F8FA' : '#F5F8FA',
      borderRadius: 30,
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const defaultValues = {
  label_id: undefined,
  caption: undefined,
  bill_type_id: undefined,
  bill_id: undefined,
  description: undefined,
  enable_midterm_payment: undefined,
};

interface formData {
  label_id: string;
  caption: string;
  bill_type_id: any;
  bill_id: string;
  description: string;
  enable_midterm_payment: boolean;
}

export default function ManagementItemModal({defaultValuesProps=undefined,renew=undefined}) {
  const params = useLocation();
  const [open, setOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState('شناسه قبض');

  const labels = useAppSelector(selectLabels);

  // const { labels } = useLabels();
  const { data: billsTypes = [] } = useBillTypesQuery({});
  const [createBill, { error }] = useCreateBillMutation();
  const [editBill, { error:errorEdit }] = useEditBillMutation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
    getValues,
    setValue,
  } = useForm<formData>({
    resolver: joiResolver(billSchema),
    mode: 'onChange',
    defaultValues:defaultValuesProps?{
      label_id:defaultValuesProps?.label_id||'',
      caption:defaultValuesProps?.caption||'',
      bill_id:defaultValuesProps?.bill_id||'',
      description:defaultValuesProps?.description||'',
      bill_type_id:{name:defaultValuesProps.bill_type_name,id:defaultValuesProps.bill_type_id,logo:defaultValuesProps.bill_type_logo},
      enable_midterm_payment:{name:defaultValuesProps?.enable_midterm_payment?'فعال':'غیر فعال',id:defaultValuesProps?.enable_midterm_payment}
    }:defaultValues,
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    console.log(watch('bill_type_id')?.id);
    if (watch('bill_type_id')?.id)
      switch (watch('bill_type_id')?.id) {
        case 7:
          setPlaceholder('پیش شماره + تلفن');
          break;
        case 8:
          setPlaceholder('شماره همراه');
          break;
        default:
          setPlaceholder('شناسه قبض');
          break;
      }
  }, [watch('bill_type_id')]);

  async function onSubmit(value: any) {
    if (typeof (value.value?.bill_id || value.bill_id) !== 'number') {
      setError('bill_id', { type: 'custom', message: 'یک مقدار عددی لاتین معتبر وارد کنید' });
      return false;
    }

    let body = {
      label_id: value.value?.label_id || value.label_id,
      caption: value.value?.caption || value.caption,
      bill_type_id: value.value?.bill_type_id?.id || value.bill_type_id?.id,
      bill_id: value.value?.bill_id || value.bill_id,
      enable_midterm_payment: value.value?.enable_midterm_payment?.id || value.enable_midterm_payment?.id,
      description: value.value?.description || value.description,
    };
    if(defaultValuesProps){
      console.log(defaultValuesProps);
      
      try{
        const res = await editBill({body,id:defaultValuesProps?.id}).unwrap();
        setOpen(!open);
        if (res.status)
          toast.success(res.message);
        renew()
      }catch (error){

      }
    }else{
      try {
        const res = await createBill(body).unwrap();
        setOpen(!open);
        if (res.status)
          toast.success(res.message);
      } catch (error) {
      }
    }
  }

  useEffect(() => {
    console.log(params.search);
    if (params?.search) {
      setOpen(!open);
      // setValue('bill_type_id', );
      console.log(billsTypes.filter((item: any) => item.id === 1)[0]);
      switch (params.search) {
        case '?water':
          setValue('bill_type_id', billsTypes.filter((item: any) => item.id === 1)[0]);
          break;
        case '?power':
          setValue('bill_type_id', billsTypes.filter((item: any) => item.id === 2)[0]);
          break;
        case '?gas':
          setValue('bill_type_id', billsTypes.filter((item: any) => item.id === 3)[0]);
          break;
        case '?mobile':
          setValue('bill_type_id', billsTypes.filter((item: any) => item.id === 7)[0]);
          break;
      }
    }
  }, [params?.search]);

  return <>
    {defaultValuesProps?<button onClick={() => setOpen(true)} className={`flex`}>
      <EditTwoIcon />
        ویرایش قبض
    </button>:<button onClick={() => setOpen(true)} className={`${s.addButton} parag-lg-b`}>
      + افزودن قبض جدید
    </button>}
    
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={s.itemModal}>
        <div className={s.modalHeader}>
          <span className="paragraph-bx">{defaultValuesProps?'ویرایش قبض':'افزودن قبض جدید'}</span>
          <div onClick={() => setOpen(!open)} className={s.iconClose}>
            <CloseTwoIcon />
          </div>
        </div>
        <form className="py-4 px-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-1 px-5 w-full">

          </div>
          <div className={s.modalForm}>
            <div>
              <Controller
                name="label_id"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    placeholder={'برچسب'}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: '#656565',
                      },
                    })}
                    options={labels}
                    styles={selectStyles}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    value={labels.filter((item: any) => item.value === getValues('label_id'))}
                    onChange={(e: any) => {
                      setValue('label_id', e.value);
                    }}
                    isSearchable={true}
                    noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
                    loadingMessage={() => 'در حال دریافت'}
                    className={'w-full'}
                    components={{
                      Option: InputOption,
                    }}
                  />
                )}
              />
              {errors.label_id?.message && <TextMessage msg={errors.label_id?.message} type="error" />}
            </div>
            <div>
              <Controller
                name="caption"
                control={control}
                render={({ field }) => (
                  <input
                    className="h-10 w-[200px] placeholder:parag-lg-b bg-white border border-gray-100"
                    {...field}
                    type="text"
                    placeholder="عنوان قبض"
                  />
                )}
              />
              {errors.caption?.message && <TextMessage msg={errors.caption?.message} type="error" />}
            </div>
            <div>
              <Controller
                name="bill_type_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    formatOptionLabel={(item) => <div className={'flex'}><img className="w-4 h-4 ml-2" src={item.logo}
                                                                              alt="item.logo" /><p>{item.name}</p>
                    </div>}
                    options={billsTypes}
                    styles={selectStyles}
                    onChange={(e) => field.onChange(e)}
                    className="w-[200px] paragraph-s"
                    getOptionLabel={(item: any) => item.name}
                    getOptionValue={(item: any) => item.id}
                    placeholder="نوع قبض"
                    noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
                    loadingMessage={() => 'در حال دریافت'}
                  />
                )}
              />
              {errors.bill_type_id?.message && <TextMessage msg={`${errors.bill_type_id?.message}`} type="error" />}
            </div>
            <div>
              {placeholder ? <Controller
                name="bill_id"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="h-10 w-[200px] placeholder:parag-lg-b bg-white border border-gray-100"
                    type="text"
                    placeholder={placeholder}
                  />
                )}
              /> : ''}
              {errors.bill_id?.message && <TextMessage msg={errors.bill_id?.message} type="error" />}
            </div>
            <div>
              <Controller
                name="enable_midterm_payment"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { name: 'فعال', id: true },
                      { name: 'غیر فعال', id: false },
                    ]}
                    styles={selectStyles}
                    onChange={(e) => field.onChange(e)}
                    className="w-[200px] paragraph-s"
                    getOptionLabel={(item: any) => item.name}
                    getOptionValue={(item: any) => item.id}
                    placeholder="پرداخت میان دوره"
                    noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
                    loadingMessage={() => 'در حال دریافت'}
                  />
                )}
              />
              {errors.enable_midterm_payment?.message &&
                <TextMessage msg={`${errors.enable_midterm_payment?.message}`} type="error" />}
            </div>
            <div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="h-10 w-[200px] placeholder:parag-lg-b bg-white border border-gray-100"
                    type="text"
                    placeholder="توضیحات"
                  />
                )}
              />
              {errors.description?.message && <TextMessage msg={errors.description?.message} type="error" />}
            </div>
          </div>
          {
            // @ts-ignore
            error && <TextMessage msg={error?.data?.message} type="error" />
          }
          <div className={s.modalButtons}>
            <button className={`${s.buttonPrimary} parag-lg-b`}>{defaultValuesProps?'ویرایش':'افزودن'}</button>
            <button className={`${s.buttonOutline} parag-lg-b`} onClick={() => setOpen(!open)}>
              انصراف
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </>;
}
